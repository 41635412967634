import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarState = new BehaviorSubject<string>('dashboard'); // Default state

  currentSidebar$ = this.sidebarState.asObservable();

  changeSidebar(sidebar: string) {
    console.log("changeSidebar : ",sidebar)
    this.sidebarState.next(sidebar);
  }
}