<div class="mt-5" class="relation_table" *ngIf="relation_table_hash; else noRelationTable">
    <ng-container *ngIf="relation_table_info">
        <div class="row">
            <div class="col-md-12">
            <!-- <h3>{{ relation_table_hash.label }}</h3> -->
                <button class="btn btn-primary pull-right  btn-md btn-sm px-2 py-1 ml-1 mb-2 mt-2 mr-3 relation_add"
                        (click)="goToTableEdit(relation_table_info)" *ngIf="relation_table_info.grant.add">
                        <i class="fa fa-plus"></i> レコードを追加
                </button>
            </div>
        </div>

        <ng-container *ngIf="relation_table_hash.sub_label">
            <div class="mb-2">
                <!-- <small>{{ relation_table_hash.sub_label }}</small> -->
            </div>
        </ng-container>
        <div *ngIf="relation_table_hash.data_a.length==0;else relationDataExists" style="text-align: center"> データはありません
        </div>
        <ng-template #relationDataExists>
            <ng-container *ngIf="view_mode && relation_table">
                <div class="mb-4" *ngFor="let _relation_data of relation_table.data_a">
                    <admin-view-table *ngIf="relation_table_info"
                                      [fields]="relation_table.getViewFields(relation_table_info)"
                                      [forms]="relation_table_info.forms" [data]="_relation_data"
                                      [table]="relation_table_hash.table"></admin-view-table>
                </div>
            </ng-container>
            <ng-container *ngIf="!view_mode">
                <admin-table [view_fields]="getRelationViewFields()|async" [is_relation_table]="true"
                             [table_info]="relation_table_info" [data_a]="relation_table.data_a"
                             [hide_scroll]="true" [isShowManageCol]="true" [reload]="reload" [isMovable]="false"
                             [child_a_by_id]="{}"></admin-table>
            </ng-container>

        </ng-template>
    </ng-container>
</div>

<ng-template #noRelationTable>
    <div class="no-relation-table">
        関連テーブルが存在しない、または権限がありません
    </div>
</ng-template>
