import {Component } from '@angular/core';
import {Router} from '@angular/router'
import {SharedService} from '../../services/shared';

@Component({
    selector: 'setting-nav-menus',
    templateUrl: './setting-nav-menus.component.html',
    styleUrls: ['./nav-menus.component.scss']
})

export class SettingNavMenusComponent{
    currentRoute: string = '/admin/dataset';

    constructor(public _share: SharedService,public _router: Router){
        
    }
   
   
    public to(page) {
      
        this.currentRoute = ''
        this.currentRoute = '/admin/'+page
       
        this._router.navigate([this._share.getAdminTable(), page]);

    }

    public toEditImportMailSetting() {
        this.currentRoute = ''
        this.currentRoute = '/admin/import_pop_mail/view/1';
        this._router.navigate([this._share.getAdminTable(), 'import_pop_mail', 'view', 1]);
    }

    public toStorageManagement() {
        this.currentRoute = ''
        this.currentRoute = '/admin/info/management';
        this._router.navigate([this._share.getAdminTable(), 'info', 'management']);
    }

    public toPayment() {
        this.currentRoute = ''
        this.currentRoute = '/admin/payment/pay';
        this._router.navigate([this._share.getAdminTable(), 'payment', 'pay']);
    }

    public toEditAdminSetting() {
        this.currentRoute = ''
        this.currentRoute = '/admin/admin_setting/edit/1';
        this._share.breadcrumbs = [
            { 'link': '/admin/dataset', 'name': '管理' },
            { 'name': 'その他の設定' }
        ];
        this._router.navigate([this._share.getAdminTable(), 'admin_setting', 'edit', 1]);
    }
}