<ul class="nav-menus ">
    <li class="nav-menus__item nav-tag">
        <a class="nav-menus__link -nowrap" href="javascript:void(0)" *ngIf="_share.isMasterUser()" (click)="to('dataset')" [ngClass]="{'active': currentRoute == '/admin/dataset'}">
            <i class="fa fa-database"></i>
            テーブル管理</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a class="nav-menus__link -nowrap" href="javascript:void(0)" *ngIf="_share.isMasterUser()  && _share.hasMenu('admin')" (click)="to('admin')" [ngClass]="{'active':currentRoute == '/admin/admin'}">
            <i class="fa fa-users"></i>
            ユーザー管理</a>
    </li>
    <li  class="nav-menus__item nav-tag">
        <a class="nav-menus__link -nowrap" href="javascript:void(0)" *ngIf="_share.isMasterUser() && _share.hasMenu('division')" (click)="to('division')" [ngClass]="{'active':currentRoute == '/admin/division'}">
            <i class="fa-solid fa-building-o"></i>
            組織管理</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a class="dropdown-item" href="javascript:void(0)" *ngIf="_share.isMasterUser() && _share.hasMenu('position')" (click)="to('position')" [ngClass]="{'active':currentRoute == '/admin/position'}">
            <i class="fa-solid fa-user-pen"></i>
            役職管理</a>
    </li>
    <li  class="nav-menus__item nav-tag">
        <a class="nav-menus__link -nowrap" href="javascript:void(0)" *ngIf="_share.isMasterUser() && _share.hasMenu('division')" (click)="to('logs')" [ngClass]="{'active':currentRoute == '/admin/logs'}">
            <i class="fa fa-history"></i>
            ログ管理</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a *ngIf="_share.hasMenu('notification') && _share.isMasterUser()" class="nav-menus__link -nowrap" href="javascript:void(0)" (click)="to('notification')" [ngClass]="{'active':currentRoute == '/admin/notification'}">
            <i class="fa-solid fa-bullhorn"></i>
            通知設定</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a *ngIf="_share.user!=null && _share.isMasterUser()" class="nav-menus__link -nowrap" href="javascript:void(0)" (click)="to('common_grant')" [ngClass]="{'active':currentRoute == '/admin/common_grant'}">
            <i class="fa fa-lock"></i>
            権限設定</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a *ngIf="_share.user!=null && _share.isMasterUser()" class="nav-menus__link -nowrap" href="javascript:void(0)" (click)="toEditImportMailSetting()" [ngClass]="{'active':currentRoute == '/admin/import_pop_mail/view/1'}">
            <i class="fa-solid fa-envelope"></i>
            メール取り込み設定</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a *ngIf="!_share.system_view_disabled && _share.isMasterUser()" class="nav-menus__link -nowrap" href="javascript:void(0)" (click)="toStorageManagement()" [ngClass]="{'active':currentRoute == '/admin/info/management'}">
            <i class="fa-solid fa-chart-line"></i>
            システム利用状況</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a *ngIf="_share.user!=null && _share.isMasterUser() && (_share.isTrial() || _share.cloud_setting['payment_method']=='paypal' || _share.cloud_setting['payment_method']=='credit')"
                   class="nav-menus__link -nowrap" href="javascript:void(0)"
                   (click)="toPayment()" [ngClass]="{'active':currentRoute == '/admin/payment/pay'}">
                    <i class="fa-solid fa-file"></i> 契約設定</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a *ngIf="_share.user!=null && (_share.isMasterUser() || _share.common_grant.invoice) && !_share.isTrial() && ( _share.cloud_setting['payment_method']=='credit')" 
        class="nav-menus__link -nowrap" href="javascript:void(0)"
        (click)="to('admin_invoices')" [ngClass]="{'active':currentRoute == '/admin/admin_invoices'}">
         <i class="fa-solid fa-file"></i> 請求情報</a>
    </li>
    <li class="nav-menus__item nav-tag">
        <a *ngIf="_share.user!=null && _share.isMasterUser()" class="nav-menus__link -nowrap" href="javascript:void(0)" (click)="toEditAdminSetting()" [ngClass]="{'active':currentRoute == '/admin/admin_setting/edit/1'}">
            <i class="fa-solid fa-sliders"></i>
            その他の設定</a>
    </li>
</ul>