import { Component, OnInit, Input } from '@angular/core';
import { RelationTable } from '../../class/RelationTable';
import {Data} from '../../class/Data';
import { SharedService } from '../../services/shared';
import { Observable } from 'rxjs/Observable';
import { TableInfo } from 'app/class/TableInfo';
import { Router } from '@angular/router'
import {Forms} from '../../class/Forms';


@Component({
  selector: 'app-relation-table',
  templateUrl: './relation-table.component.html',
  styleUrls: ['./relation-table.component.css']
})

export class RelationTableComponent implements OnInit {

  @Input('data') data: Data = new Data(new TableInfo([]));
    @Input() relation_table_hash: any
    // public relation_table_info_by_table: Object = {};
    public relation_table: RelationTable = null;
    public relation_table_info: TableInfo = null;
    @Input('view_mode') view_mode : boolean;
    
  constructor(
    public _share: SharedService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
      this._share.getTableInfo(this.relation_table_hash.table).subscribe(_table_info => {
          this.relation_table_info = _table_info;
          this.relation_table = new RelationTable(this.relation_table_hash, _table_info)
      });
  }

    public getRelationViewFields(): Observable<Array<string>> {
    return new Observable(observer => {
        this._share.getTableInfo(this.relation_table.table).subscribe(_table_info => {
        if (!_table_info) {
          observer.next([]);
        } else {
            observer.next(this.relation_table.getViewFields(_table_info))
        }
      })
    });
  }


  goToTableEdit(table_info: TableInfo): void {
    this._router.navigate([this._share.getAdminTable(), table_info.admin_table_setting.table, 'edit', 'new']);
  }

}
