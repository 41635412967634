

<div *ngIf="selectedData" class="row">
    <div class="col-md-2">
        <select name="" id="" class="form-control" [(ngModel)]="selectedValue" (change)="onChangeRelatedRecord($event)">
            <ng-container *ngFor="let data of relational">
                <option value="{{data.id}}">{{data.name}}</option>
            </ng-container>
        </select>
    </div>
    <div class="col-md-9 display-type-select">
        <div class="row pull-right">
            
            <label class="">表示方法 </label>
            <div class="ml-2"  dropdown>
                <button dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle btn-toggle-header display-type" >
                    {{selectedViewMode}}
                </button>
              
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right"  role="menu">
                    <li role="menuitem" *ngIf="selectedData.view_mode">
                        <a class="dropdown-item" (click)="changeViewMode(false)" >テーブル</a>
                    </li>
                    <li role="menuitem" *ngIf="!selectedData.view_mode">
                        <a class="dropdown-item" (click)="changeViewMode(true)">カード</a>
                    </li>
                 
                </ul>
            </div>
        </div>
    
    </div>
</div>
<app-relation-table id="relation_tables" [data]="data" [relation_table_hash]="getRelationTableName(selectedData)" [view_mode]="selectedData?.view_mode"></app-relation-table> 

<!-- <div *ngIf="fieldrows" class="table table-bordered mb-0 table-admin-view">
    <div *ngFor="let row of fieldrows;" style="display:flex" class="table-admin-view__1row pc-view-row">
        <ng-container *ngFor="let field of row">
           
         
            <ng-container *ngIf="!field.hasOwnProperty('Field') || forms.byFieldName(field['Field']).type == 'relation_table' && !selectDataMode">
              
                <app-relation-table id="relation_tables" [data]="data" [relation_table]="getRelationTableName(field)"></app-relation-table>
            </ng-container>
        </ng-container>
    </div>
</div>  -->

<!-- <div  *ngIf="fieldrows" class="table table-bordered mb-0 table-admin-view">
 
        <app-relation-table 
        [data]="data" 
        [fieldrows]="fieldrows"
        [selectDataMode]="selectDataMode">
        </app-relation-table>
 
</div> -->