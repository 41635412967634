import {Component, OnInit, Input} from '@angular/core';
import {SharedService} from '../../services/shared';

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    @Input() breadcrumbs;
    constructor(public _share: SharedService) {
    }
    ngOnInit() {
        console.log('breadcrumbs init');
        console.log(this.breadcrumbs);
        this._share.breadcrumbs = this.breadcrumbs;
        console.log('breadcrumbs after');
        console.log(this.breadcrumbs);
    }
}
