import {Component, Input, OnInit, AfterViewInit,OnChanges,SimpleChanges } from '@angular/core';
import {SharedService} from '../../services/shared';
import {MenuNode} from '../../class/MenuNode';
import {Dashboard} from '../../class/Dashboard';


@Component({
    selector: 'dashboard-nav-menus',
    templateUrl: './dashboard-nav-menus.component.html',
    styleUrls: ['./nav-menus.component.scss']
})
export class DashboardNavMenusComponent implements OnInit,OnChanges, AfterViewInit {
   
    

    @Input() menu_root: MenuNode;
    @Input() menu_root_dashboard: MenuNode;
    @Input() show_only_directory: boolean;
    @Input() show_root: boolean;
    @Input() show_dashboard: any[] = [];
    activeTab: string;
    
    constructor(public _share: SharedService) {}

    ngOnInit() {
        this.setDefaultActiveTab();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['show_dashboard'] && changes['show_dashboard'].currentValue) {
            this.setDefaultActiveTab();
        }
    }

    ngAfterViewInit() {
        this.setDefaultActiveTab();
    }
   

    setDefaultActiveTab() {
        console.log('show_dashboard:', this.show_dashboard);
        console.log('Setting activeTab...');
        
        if (!this.show_dashboard || this.show_dashboard.length === 0) {
            this.activeTab = "HOME";
        } else {
            this.activeTab = this.show_dashboard[0]?.name || "HOME";
        }
    }

    onTabChange(dashboard: any) {
     
        this._share.sendTabData(dashboard)
        this.activeTab = dashboard.name;
    }

    isActive(tabName): boolean {
        
        return this.activeTab === tabName;
    }
  
}



