<ul class="nav-menus " *ngIf="this.show_dashboard">
  
    <ng-container *ngFor="let dashboard of this.show_dashboard"  >
     
        <li class="nav-menus__item nav-tag" *ngIf="!show_only_directory">
         
            <a class="nav-menus__link -nowrap" [class.active]="isActive(dashboard.name)" routerLinkActive="active" (click)="onTabChange(dashboard)">
                
                <span>{{dashboard.name}}</span>
                
            </a>
        </li>

    </ng-container>
    
</ul>



