<div *ngIf="loading;then loading_block else loaded_block"></div>
<ng-template #loading_block>
    <div class="loader">Loading...</div>

</ng-template>
<ng-template #loaded_block>
    <ng-container *ngIf="data==null">
        <div class="data_not_found" style="padding:40px;text-align:center">
            DATA NOT FOUND
        </div>
    </ng-container>

    <ng-container *ngIf="data!=null">
        <tabset class="mt-2" #modalTabs>
            <tab heading="レコード情報">
                
               <admin-view-table [filter]="customFilter" [table]="table_info.table" [fields]="fields" [forms]="table_info.forms" [data]="data"
                                  [extend_headers]="extend_headers" [selectDataMode]="selectDataMode" [relation_table_a]="data.relation_table_a"></admin-view-table>
        
               
            </tab>
            <tab heading="子テーブル情報" (selectTab)="getChild()">
                <!-- 子テーブル -->
             
                <div *ngIf="condition" class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-2">
                                <select name="" id="" class="form-control child-select" [(ngModel)]="selectedChildValue" (change)="onChangeChild($event)">
                                    <ng-container *ngFor="let label of childLabel">
                                        <option value="{{label}}">{{label}}</option>
                                    </ng-container>
                                </select>
                            </div>
                           
                        </div>
                    </div>
                    
                    <div class="col-md-3">
                        <div class="row pull-right">
                            <label class="">表示方法 </label>
                            <div class="ml-2"   dropdown>
                                <button dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle btn-toggle-header display-type" >
                                    {{selectedDisplayType}}
                                </button>
                              
                                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right"  role="menu">
                                    <li role="menuitem" *ngIf="selectedDisplayType !== 'テーブル'">
                                        <a class="dropdown-item"  (click)="onSelectDisplayType('table')">テーブル</a>
                                    </li>
                                    <li role="menuitem" *ngIf="selectedDisplayType !== 'カード'">
                                        <a class="dropdown-item"  (click)="onSelectDisplayType('normal')">カード</a>
                                    </li>
                                    <li role="menuitem" *ngIf="selectedDisplayType !== 'イメージ'">
                                        <a class="dropdown-item"  (click)="onSelectDisplayType('image')">イメージ</a>
                                    </li>
                                    <li role="menuitem" *ngIf="selectedDisplayType !== 'チップ'">
                                        <a class="dropdown-item"  (click)="onSelectDisplayType('chip')">チップ</a>
                                    </li>
                                 
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let child of selectedChildData">
                  
                    <ng-template [ngIf]="condition">
                        <!-- {{child.table}} -->
                        <ng-container *ngIf="child.menu.multiple_mode=='normal'">
                            <ng-container *ngFor="let data of data.child_data_by_table[child.table]">
                                <div class="" *ngIf="child.forms != undefined">
                                    <admin-view-table class="view-children" [ignore_fieldname_a]="['created','updated','id','order','admin_id','updated_admin_id']"
                                                      [fields]="child.fields" [forms]="child.forms" [data]="data" [table]="child.menu.table" [selectDataMode]="selectDataMode">
                                    </admin-view-table>
                                </div>
                                <div style="display:flex;justify-content: flex-end; ">
                                    <ng-container *ngFor="let button of child.menu.button_functions">
                                        <input class="form-control" style="width:40px" [OnlyNumber] type="text" [(ngModel)]="button.value">
                                        <button class="btn btn-info ml-1 mt-0" (click)="call_func(button,data.raw_data['id'])">{{button.button_label}}</button>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="child.menu.multiple_mode=='table'">
                            <admin-view-table-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                                [data_a]="data.child_data_by_table[child.table]"></admin-view-table-list>
                        </ng-container>
                        <ng-container *ngIf="child.menu.multiple_mode=='image'">
                            <admin-view-image-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                                                   [data_a]="data.child_data_by_table[child.table]"></admin-view-image-list>
                        </ng-container>
                        <ng-container *ngIf="child.menu.multiple_mode=='chip' || child.menu.multiple_mode=='chip_toxi'">
                            <admin-view-chip-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                                                  [data_a]="data.child_data_by_table[child.table]"></admin-view-chip-list>
                        </ng-container>
                    </ng-template>
                </ng-container>
            </tab>
            <tab heading="関連レコード情報"> 
                <admin-relation-table [filter]="customFilter" [table]="table_info.table" [fields]="fields" [forms]="table_info.forms" [data]="data"
                                  [extend_headers]="extend_headers" [selectDataMode]="selectDataMode" [relation_table_a]="data.relation_table_a"></admin-relation-table>
            </tab>
            <tab heading="ワークフロー履歴" >
               
                <div *ngIf="contentHtml!=undefined">
                    <div [innerHTML]="contentHtml | safeHtml"></div>
                </div>
               
                <!-- {{contentHtml}} -->
                <!-- {{activeTab}}
                <div *ngIf="activeTab === 'workflow'">

                    <div [innerHTML]="workflowContent"></div>
                </div> -->

                <!-- Display loading indicator while content is loading -->
                <!-- <div *ngIf="!isWorkflowContentLoaded">Loading...</div> -->
                
                <!-- <div *ngIf="workflowContent" [innerHTML]="workflowContent"></div> -->
            </tab>
        </tabset>
       
    </ng-container>
</ng-template>
